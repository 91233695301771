import {
  RemixImage,
  SectionWrapper,
  SectionContentWrapper,
  BlockHeadline,
  BlockDescription,
} from "../../display";
import { Button, DummySearchLink } from "../../interactive";
import { HiArrowRight } from "react-icons/hi";

import type { HeroContentTypes } from "./types";

import styles from "../../../../styles/components/pages/homepage/hero.css";

Hero.links = [
  { rel: "stylesheet", href: styles },
  {
    rel: "stylesheet",
    href: RemixImage.styles,
  },
  {
    rel: "stylesheet",
    href: SectionWrapper.styles,
  },
  {
    rel: "stylesheet",
    href: Button.styles,
  },
  {
    rel: "stylesheet",
    href: DummySearchLink.styles,
  },
  {
    rel: "stylesheet",
    href: SectionContentWrapper.styles,
  },
  {
    rel: "stylesheet",
    href: BlockHeadline.styles,
  },
];

export function Hero({ heroData }: { heroData: HeroContentTypes }) {
  let {
    heroStrapline,
    heroDescription,
    image: { imageUrl, imageDescription },
    dealsButton: { buttonText, href },
    heroSearchbarPlaceholderText,
  } = heroData;

  return (
    <>
      <div className="hero-wrapper">
        <RemixImage
          svgColorFilter="dark-blue"
          sanityUrl={imageUrl}
          alt={imageDescription}
          loading="eager"
        />
        <SectionWrapper className="hero-content-wrapper">
          <SectionContentWrapper>
            <div className="hero-content-block">
              <BlockHeadline
                className="hero-block-headline"
                hSize={1}
                blockHeadlineData={heroStrapline}
              />
              <span className="block tb:h1"></span>
              <BlockDescription
                className="hero-description-block hidden tb:block"
                blockDescriptionData={heroDescription}
              />
              <span className="hidden tb:h-4 tb:block"></span>
              <div className="hero-search-wrapper">
                <DummySearchLink
                  href="/search"
                  placeholderText={heroSearchbarPlaceholderText}
                />
              </div>
              <span className="block h-8"></span>
              <div className="deals-button-wrapper">
                <Button
                  className="hero-deals-button"
                  elevation="md"
                  type="link"
                  href={href}
                >
                  <div className="button-content-wrapper">
                    <div className="button-text">{buttonText}</div>

                    <div className="button-icon-wrapper">
                      <HiArrowRight aria-hidden="true" />
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </SectionContentWrapper>
        </SectionWrapper>
      </div>
    </>
  );
}
