import { Link } from "remix";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

import type { BlockFourContentTypes } from "./types";
import styles from "~/styles/components/pages/homepage/block-four.css";
import {
  PreHeadlineText,
  SectionWrapper,
  SectionContentWrapper,
  RemixImage,
  BlockHeadline,
  BlockDescription,
} from "~/src/components/display";
import { ButtonWithIcon } from "~/src/components/interactive";

const HeadlineBlockRenderer = (props: any) => {
  const { style = "normal" } = props.node;

  if (style === "normal") {
    return <h5 className="block-four-headline">{props.children}</h5>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const DescriptionTextBlockRenderer = (props: any) => {
  const { style = "normal" } = props.node;

  if (style === "normal") {
    return <p className="block-four-description">{props.children}</p>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

BlockFour.links = [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: PreHeadlineText.styles },
  ...ButtonWithIcon.links,
];

export function BlockFour({
  blockFourData,
}: {
  blockFourData: BlockFourContentTypes;
}) {
  let {
    preHeadlineText,
    headline,
    description,
    descriptionParagraphTwo,
    sendMessageLinks,
    contactUsButton,
    images: [{ imageDescription, imageUrl }],
  } = blockFourData;

  return (
    <>
      <div className="block-four-wrapper">
        <span className="block h-4"></span>
        <SectionWrapper>
          <div className="hp-b4-fs-flex-wrapper">
            <div className="block-four-content-wrapper">
              <SectionContentWrapper>
                <PreHeadlineText>{preHeadlineText}</PreHeadlineText>
                <BlockHeadline hSize={3} blockHeadlineData={headline} />
                <BlockDescription blockDescriptionData={description} />
                <span className="block h-1 mm:h-2"></span>
                <BlockDescription
                  blockDescriptionData={descriptionParagraphTwo}
                />
                <span className="block h-1 mm:h-2"></span>

                <ul>
                  {sendMessageLinks.map((messageLink) => {
                    return (
                      <SendMessageLink
                        key={messageLink.buttonText}
                        buttonIconAltText={messageLink.buttonIconAltText}
                        buttonIconUrl={messageLink.buttonIconUrl}
                        href={messageLink.href}
                        buttonText={messageLink.buttonText}
                      />
                    );
                  })}
                </ul>
                <span className="block h-4 mm:h-5"></span>
                <ButtonWithIcon
                  className="block-four-contact-us-with-icon-button"
                  size="medium"
                  type="link"
                  href={contactUsButton.href}
                  iconUrl={contactUsButton.iconUrl}
                  iconAltText={contactUsButton.iconAltText}
                >
                  {contactUsButton.buttonText}
                </ButtonWithIcon>
              </SectionContentWrapper>
            </div>
            <div className="hp-b4-image-content-wrapper">
              <SectionContentWrapper className="hp-b4-image-content-block">
                <>
                  <span className="block h-12 lt:hidden"></span>
                  <div className="block-four-image-wrapper elevation-sm">
                    <div className="block-four-image-container elevation-md">
                      <RemixImage sanityUrl={imageUrl} alt={imageDescription} />
                    </div>
                  </div>
                </>
              </SectionContentWrapper>
            </div>
          </div>
        </SectionWrapper>
      </div>
    </>
  );
}

function SendMessageLink({
  buttonIconAltText,
  buttonIconUrl,
  buttonText,
  href,
}: {
  buttonIconAltText: string;
  buttonIconUrl: string;
  buttonText: string;
  href: string;
}) {
  return (
    <li className="hp-b4-send-message-link-list-item-wrapper mb-5 mm:mb-6">
      <Link
        prefetch="intent"
        className="hp-b4-send-message-link-wrapper"
        to={href}
      >
        <div className="hp-b4-send-message-link-icon-wrapper">
          <RemixImage
            objectFit="contain"
            sanityUrl={buttonIconUrl}
            alt={buttonIconAltText}
          />
        </div>
        <div className="span block hp-b4-send-message-link-text">
          {buttonText}
        </div>
      </Link>
    </li>
  );
}
