import type { MetaFunction, LinksFunction, LoaderFunction } from "remix";
import { useLoaderData, json } from "remix";
import { Header } from "../src/components/display";
import {
  Hero,
  BlockTwo,
  BlockThree,
  BlockFour,
} from "../src/components/pages/homepage";
import groq from "groq";

import { sanityReadClient } from "../src/utils/sanity/index.server";
import { generateMetaFunctionData, useMetaFunctionData } from "~/src/utils/seo";

import type { HomepageContent } from "~/src/components/pages/homepage";

export let meta: MetaFunction = ({ data }) => {
  let metaData = data.metaFunctionData;
  let metaFunctionData = useMetaFunctionData(metaData);
  return metaFunctionData;
};

export let links: LinksFunction = () => {
  return [
    ...Hero.links,
    ...BlockTwo.links,
    ...BlockThree.links,
    ...BlockFour.links,
    ...Header.links,
  ];
};

export let loader: LoaderFunction = async ({ request }) => {
  const { href } = new URL(request.url);

  const heroQueryFragment = groq`hero{dealsButton{buttonText, href, 'buttonIconUrl': buttonIcon.image.asset->url, 'buttonIconAltText': buttonIcon.imageDescription}, heroDescription, heroSearchbarPlaceholderText, heroStrapline, image{'imageUrl': image.asset->url, imageDescription}},`;
  const blockTwoQueryFragment = groq`blockTwo{'images': blockImages.images[]{imageDescription, "imageUrl": image.asset->url}, description, 'featureItemLinks': featureItemsLinks.links[]{href, buttonText, 'buttonIconUrl': buttonIcon.image.asset->url, 'buttonIconAltText': buttonIcon.imageDescription}, descriptionParagraphTwo, headline, preHeadlineText, searchBoxPlaceholderText, searchBoxTitle},`;
  const blockThreeQueryFragment = groq`blockThree{blockThreeHeadline, backgroundImage{imageDescription, 'imageUrl': image.asset->url}, siteFeaturesItems[]{description, headline, icon{'altText': imageDescription, 'iconUrl': image.asset->url}, 'links': links.links[]{buttonText, href}}},`;
  const blockFourQueryFragment = groq`blockFour{'images': blockImages.images[]{'imageUrl': image.asset->url, imageDescription}, contactUsButton{buttonText, href, 'iconUrl': buttonIcon.image.asset->url, 'iconAltText': buttonIcon.imageDescription}, description, descriptionParagraphTwo, headline, preHeadlineText, 'sendMessageLinks': sendUsAMessageLinksList.links[]{buttonText, href, 'buttonIconUrl': buttonIcon.image.asset->url, 'buttonIconAltText': buttonIcon.imageDescription}}`;
  const homepageQuery = groq`*[_type == 'homepage']{homepageContent{${heroQueryFragment} ${blockTwoQueryFragment} ${blockThreeQueryFragment} ${blockFourQueryFragment}}}`;

  try {
    var sanityHomepageFetchResults = await sanityReadClient.fetch(
      homepageQuery
    );

    var hompageContentBlocks: HomepageContent =
      sanityHomepageFetchResults[0].homepageContent;

    var { hero, blockTwo, blockThree, blockFour } = hompageContentBlocks;
  } catch (err) {
    console.error("sanityHomepageFetchResults Error: ", err);
  }

  // Page Metadata
  {
    const {
      image: { imageDescription, imageUrl },
    } = hero;

    const imageOptions = [{ imageUrl, imageAlt: imageDescription }];

    var metaFunctionData = generateMetaFunctionData({
      imageOptions,
      metaUrl: href,
      metaTitle: "Fort St John's Business Directory",
      metaDescription:
        "Find products from local Fort St John & Area Businesses. Serving the BC Peace Region.",
    });
  }

  return json({
    homepageData: {
      hero,
      blockTwo,
      blockThree,
      blockFour,
    },
    metaFunctionData,
  });
};

export default function Index() {
  let { homepageData }: { homepageData: HomepageContent } = useLoaderData();
  let { hero, blockTwo, blockThree, blockFour } = homepageData;

  return (
    <>
      <div className="relative">
        <Hero heroData={hero} />
        <BlockTwo blockTwoData={blockTwo} />
        <BlockThree blockThreeData={blockThree} />
        <BlockFour blockFourData={blockFour} />
      </div>
    </>
  );
}
