//@ts-ignore
import { FaChevronRight } from "react-icons/fa";
import { Link } from "remix";

import type { BlockTwoContentTypes } from "./types";
import {
  SectionWrapper,
  SectionContentWrapper,
  RemixImage,
  PreHeadlineText,
  BlockHeadline,
  BlockDescription,
} from "~/src/components/display";

import { DummySearchLink } from "~/src/components/interactive";

import styles from "~/styles/components/pages/homepage/block-two.css";

BlockTwo.links = [
  { rel: "stylesheet", href: styles },
  {
    rel: "stylesheet",
    href: PreHeadlineText.styles,
  },
  {
    rel: "stylesheet",
    href: DummySearchLink.styles,
  },
  {
    rel: "stylesheet",
    href: BlockHeadline.styles,
  },
  {
    rel: "stylesheet",
    href: BlockDescription.styles,
  },
];

export function BlockTwo({
  blockTwoData,
}: {
  blockTwoData: BlockTwoContentTypes;
}) {
  let {
    description,
    featureItemLinks,
    descriptionParagraphTwo,
    headline,
    images,
    preHeadlineText,
    searchBoxTitle,
    searchBoxPlaceholderText,
  } = blockTwoData;
  return (
    <div className="block-two-wrapper">
      <SectionWrapper>
        <span className="block h-4"></span>
        <div className="block-two-content-wrapper">
          <SectionContentWrapper>
            <PreHeadlineText>{preHeadlineText}</PreHeadlineText>
            <BlockHeadline hSize={2} blockHeadlineData={headline} />
            <BlockDescription blockDescriptionData={description} />
            <span className="block h-1 mm:h-2"></span>
            {featureItemLinks.map((listItem) => {
              return (
                <FeatureLinkItem key={listItem.buttonText} href={listItem.href}>
                  {listItem.buttonText}
                </FeatureLinkItem>
              );
            })}
            <span className="block h-1 mm:h-2"></span>
            <BlockDescription blockDescriptionData={descriptionParagraphTwo} />
          </SectionContentWrapper>
          <div className="block-two-image-content-wrapper">
            <div className="block-two-image-wrapper elevation-lg">
              <RemixImage
                screenSizeToUseImage={{
                  tb: "10000",
                  lt: "10000",
                }}
                sanityUrl={images[0].imageUrl}
                alt={images[0].imageDescription}
              ></RemixImage>
            </div>
          </div>
        </div>
        <span className="block"></span>
      </SectionWrapper>
      <div className="block-two-search-wrapper elevation-md">
        <SectionWrapper>
          <SectionContentWrapper className="block-two-search-content-wrapper">
            <div className="block-two-search-title">{searchBoxTitle}</div>
            <span className="block h-5"></span>
            <DummySearchLink
              href="/search"
              placeholderText={searchBoxPlaceholderText}
            />
          </SectionContentWrapper>
        </SectionWrapper>
      </div>
    </div>
  );
}

function FeatureLinkItem({
  children,
  href,
}: {
  children: string;
  href: string;
}) {
  return (
    <Link prefetch="intent" to={href} className="list-item-wrapper">
      <div className="list-item-icon-wrapper">
        <FaChevronRight aria-hidden="true" />
      </div>
      <div className="list-item-title">{children}</div>
    </Link>
  );
}
