import { Link } from "remix";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

import type { BlockThreeContentTypes } from "./types";
import styles from "~/styles/components/pages/homepage/block-three.css";

import {
  SectionWrapper,
  SectionContentWrapper,
  RemixImage,
} from "~/src/components/display";

BlockThree.links = [{ rel: "stylesheet", href: styles }];

const SectionHeadlineBlockRenderer = (props: any) => {
  const { style = "normal" } = props.node;

  if (style === "normal") {
    return <h3 className="block-three-headline">{props.children}</h3>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const FeatureHeadlineBlockRenderer = (props: any) => {
  const { style = "normal" } = props.node;

  if (style === "normal") {
    return (
      <div className="balance-text block-three-feature-headline">
        {props.children}
      </div>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const FeatureDescriptionTextBlockRenderer = (props: any) => {
  const { style = "normal" } = props.node;

  if (style === "normal") {
    return (
      <p className="balance-text block-three-feature-description">
        {props.children}
      </p>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

export function BlockThree({
  blockThreeData,
}: {
  blockThreeData: BlockThreeContentTypes;
}) {
  let {
    backgroundImage: { imageUrl, imageDescription },
    siteFeaturesItems,
    blockThreeHeadline,
  } = blockThreeData;
  return (
    <>
      <div className="block-three-wrapper elevation-md">
        <div className="block-three-background-image-wrapper">
          <RemixImage sanityUrl={imageUrl} alt={imageDescription} />
        </div>
        <span className="block h-4 mm:h-6"></span>
        <SectionWrapper className="block-three-section-content-wrapper !relative">
          <SectionContentWrapper>
            {/* <BlockContent
              blocks={blockThreeHeadline[0]}
              serializers={{ types: { block: SectionHeadlineBlockRenderer } }}
            />
            <span className="block h-8"></span> */}
          </SectionContentWrapper>
          <ul className="block-three-site-features-list">
            {siteFeaturesItems.map((siteFeature) => {
              return (
                <SiteFeaturesLinkItem
                  key={siteFeature.icon.iconUrl}
                  description={siteFeature.description}
                  headline={siteFeature.headline}
                  icon={siteFeature.icon}
                  links={siteFeature.links}
                />
              );
            })}
          </ul>
        </SectionWrapper>
      </div>
    </>
  );
}

function SiteFeaturesLinkItem({
  description,
  headline,
  icon,
  links,
}: {
  description: any[];
  headline: any[];
  icon: { altText: string; iconUrl: string };
  links: { buttonText: string; href: string }[];
}) {
  return (
    <li className="block-three-link-list-item">
      <Link
        prefetch="intent"
        className="block-three-link-item-wrapper elevation-md"
        to={links[0].href}
      >
        <div className="block-three-link-icon-wrapper">
          <RemixImage
            objectFit="contain"
            sanityUrl={icon.iconUrl}
            alt={icon.altText}
          />
        </div>
        <div className="block-three-link-content-wrapper">
          <BlockContent
            blocks={headline[0]}
            serializers={{ types: { block: FeatureHeadlineBlockRenderer } }}
          />
          <span className="block h-2 mm:h-3"></span>
          <BlockContent
            blocks={description[0]}
            serializers={{
              types: { block: FeatureDescriptionTextBlockRenderer },
            }}
          />
        </div>
      </Link>
    </li>
  );
}
